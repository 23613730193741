<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title class="mb-0 pb-0">
            12. Повышенная опасность продуктов горения
          </v-card-title>
        </v-col>
        <v-col cols="6">
          <v-card-text class="title font-weight-light pa-0">
            <p>Продукты горения (ПГ) вредно воздействуют на организм человека.</p>
            <p>При пожаре они являются носителями теплоты, продуктов неполного и полного горения, токсичных веществ и твердых несгораемых частиц.</p>
            <p>За пределами помещений, в которых возник пожар, температура ПГ может оказаться неопасной для человека, зато содержание продуктов неполного горения может послужить причиной возникновения опасных ситуаций.</p>
          </v-card-text>
          <v-card
            class="pa-2 d-flex justify-center align-center"
            full-width
            flat

            >
            <v-img
              lazy-src="img/material/stopwatch.svg"
              max-width="80"
              src="img/material/stopwatch.svg"
              class="ma-1"
            ></v-img>
            <v-spacer></v-spacer>
            <v-card-text class="title font-weight-light">
              быстрое распространение огня и токсичных продуктов горения вверх, внутри и снаружи здания
            </v-card-text>
          </v-card>
          <v-card class="pa-5">
            <v-card-title>
              Предельно допустимые значения токсичных газов ПГ (кг/м³)
            </v-card-title>
            <v-card-text class="title font-weight-light">
              <v-icon color="red" class="mr-3">mdi-checkbox-multiple-marked-circle-outline</v-icon>
              <span class="title font-weight-bold">2,3 х 10<sup>-6</sup></span> – хлороводород HCL
            </v-card-text>
            <v-card-text class="title font-weight-light">
              <v-icon color="red" class="mr-3">mdi-checkbox-multiple-marked-circle-outline</v-icon>
              <span class="title font-weight-bold">1,16 х 10<sup>-3</sup></span> – угарный газ СО
            </v-card-text>
            <v-card-text class="title font-weight-light">
              <v-icon color="red" class="mr-3">mdi-checkbox-multiple-marked-circle-outline</v-icon>
              <span class="title font-weight-bold">0,11</span> – углекислый газ СО<sub>2</sub>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" class="d-flex flex-column justify-space-around">
          <div height="100%" class="d-flex flex-column justify-space-around mt-10">
            <div class="d-flex justify-end mb-10">
              <span class="mr-10">
                <v-dialog
                  v-model="dialog1"
                  width="50%"
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      rounded
                      color="red title"
                      dark
                      x-large
                      v-bind="attrs"
                      v-on="on"
                    >
                      СО<sub>2</sub>
                    </v-btn>
                  </template>

                  <v-card class="noselect">
                    <v-card-title class="headline">
                      Диоксид углерода, углекислый газ, СО<sub>2</sub>
                    </v-card-title>

                    <v-card-title class="title red--text">
                      Воздействие:
                    </v-card-title>

                    <v-card-text class="title font-weight-light">
                    учащение дыхания и увеличение легочной вентиляции, сосудорасширающее действие, сдвиг pH крови, повышения уровня адреналина, нарушение поступления кислорода из легких в ткани и кислородное голодание.
                    </v-card-text>

                    <v-card-title class="title red--text">
                      Симптомы:
                    </v-card-title>

                    <v-card-text class="title font-weight-light">
                      потеря способности рассуждать, равнодушие, оцепенение, головокружение, депрессия, нарушение координации движений.
                    </v-card-text>

                    <v-card-title class="title red--text">
                      Концентрации:
                    </v-card-title>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      <span class="title font-weight-bold">3-4,5%</span> - опасен при вдыхании в течение нескольких минут;
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      <span class="title font-weight-bold">12%</span> - потеря сознания, смерть в течение нескольких минут;
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      <span class="title font-weight-bold">20%</span> - немедленная потеря сознания и смерть.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red"
                        text
                        @click="dialog1 = false"
                      >
                        Закрыть
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </span>
              <span class="mr-10">
                <v-dialog
                  v-model="dialog2"
                  width="50%"
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      rounded
                      color="red title"
                      dark
                      x-large
                      v-bind="attrs"
                      v-on="on"
                    >
                      СО
                    </v-btn>
                  </template>

                  <v-card class="noselect">
                    <v-card-title class="headline">
                      Оксид углерода, угарный газ, СО
                    </v-card-title>

                    <v-card-title class="title red--text">
                      Воздействие:
                    </v-card-title>

                    <v-card-text class="title font-weight-light">
                      в результате соединения с гемоглобином крови, нарушается доставка кислорода к тканям организма.
                    </v-card-text>

                    <v-card-title class="title red--text">
                      Симптомы:
                    </v-card-title>

                    <v-card-text class="title font-weight-light">
                      головная боль, усталость, удушье, головокружение, боль в груди, сухой кашель, рвота, галлюцинации, повышение артериального давления.
                    </v-card-text>

                    <v-card-title class="title red--text">
                      Концентрации:
                    </v-card-title>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      <span class="title font-weight-bold">0,08%</span> - легкое головокружение;
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      <span class="title font-weight-bold">0,5%</span> - смертельное отравление через 20 минут;
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      <span class="title font-weight-bold">1,3%</span> - смерть в результате 2-3 вдохов.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red"
                        text
                        @click="dialog2 = false"
                      >
                        Закрыть
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </span>
              <span class="mr-10">
                <v-dialog
                  v-model="dialog3"
                  width="50%"
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      rounded
                      color="red title"
                      dark
                      x-large
                      v-bind="attrs"
                      v-on="on"
                    >
                      HCl
                    </v-btn>
                  </template>

                  <v-card class="noselect">
                    <v-card-title class="headline">
                      Хлороводород, хлористый водород, HCl
                    </v-card-title>

                    <v-card-title class="title red--text">
                      Воздействие:
                    </v-card-title>

                    <v-card-text class="title font-weight-light">
                      снижает возможность ориентации человека; соприкасаясь с влажным глазным яблоком, превращается в соляную кислоту.
                    </v-card-text>

                    <v-card-title class="title red--text">
                      Симптомы:
                    </v-card-title>

                    <v-card-text class="title font-weight-light">
                      спазмы дыхания, воспалительные отеки, нарушение функций дыхания.
                    </v-card-text>

                    <v-card-title class="title red--text">
                      Концентрации:
                    </v-card-title>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      <span class="title font-weight-bold">2000-3000 мг/м</span> – летальная концентрация при действии в течении нескольких минут.
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red"
                        text
                        @click="dialog3 = false"
                      >
                        Закрыть
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </span>
            </div>
            <div>
              <div class="d-flex justify-end mb-10">
                <span class="mr-10">
                  <v-dialog
                    v-model="dialog4"
                    width="50%"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        rounded
                        color="red title"
                        dark
                        x-large
                        v-bind="attrs"
                        v-on="on"
                      >
                        HCN
                      </v-btn>
                    </template>

                    <v-card class="noselect">
                      <v-card-title class="headline">
                        Циановодород (цианистый водород, синильная кислота) HCN
                      </v-card-title>

                      <v-card-title class="title red--text">
                        Воздействие:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        нарушение тканевого дыхания, паралич нервных центров.
                      </v-card-text>

                      <v-card-title class="title red--text">
                        Концентрации:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        <span class="title font-weight-bold">240-360 мг/м3</span> - смерть в течении 5-10 минут;
                      </v-card-text>
                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        <span class="title font-weight-bold">420-500 мг/м3 </span> - быстрая смерть.
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red"
                          text
                          @click="dialog4 = false"
                        >
                          Закрыть
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </span>
                <span class="mr-10">
                  <v-dialog
                    v-model="dialog5"
                    width="50%"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        rounded
                        color="red title"
                        dark
                        x-large
                        v-bind="attrs"
                        v-on="on"
                      >
                        HF
                      </v-btn>
                    </template>

                    <v-card class="noselect">
                      <v-card-title class="headline">
                        Фтороводород (фтористый водород) HF
                      </v-card-title>

                      <v-card-title class="title red--text">
                        Воздействие:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        язвы на слизистых оболочках глаз и дыхательных путей, носовые кровотечения, спазмы гортани и бронхов, поражение ЦНС, печени, сердечно-сосудистая недостаточность.
                      </v-card-text>

                      <v-card-title class="title red--text">
                        Концентрации:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        <span class="title font-weight-bold">45-135 мг/м3</span> – опасен для жизни.
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red"
                          text
                          @click="dialog5 = false"
                        >
                          Закрыть
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </span>
                <span class="mr-10">
                  <v-dialog
                    v-model="dialog6"
                    width="50%"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        rounded
                        color="red title"
                        dark
                        x-large
                        v-bind="attrs"
                        v-on="on"
                      >
                        NO<sub>2</sub>
                      </v-btn>
                    </template>

                    <v-card class="noselect">
                      <v-card-title class="headline">
                        Диоксид азота, NO<sub>2</sub>
                      </v-card-title>

                      <v-card-title class="title red--text">
                        Воздействие:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        кислородная недостаточность организма, обусловленная поражением дыхательных путей.
                      </v-card-text>

                      <v-card-title class="title red--text">
                        Концентрации:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        <span class="title font-weight-bold">510-760 мг/м3</span> – при вдыхании в течении 5 минут развивается бронхопневмания;
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        <span class="title font-weight-bold">950 мг/м3</span> – отек легких.
                      </v-card-text>
                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red"
                          text
                          @click="dialog6 = false"
                        >
                          Закрыть
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </span>
              </div>
              <div class="d-flex justify-end mb-10">
                <span class="mr-10">
                  <v-dialog
                    v-model="dialog7"
                    width="50%"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        rounded
                        color="red title"
                        dark
                        x-large
                        v-bind="attrs"
                        v-on="on"
                      >
                        NH<sub>3</sub>
                      </v-btn>
                    </template>

                    <v-card class="noselect">
                      <v-card-title class="headline">
                        Аммиак, NH<sub>3</sub>
                      </v-card-title>

                      <v-card-title class="title red--text">
                        Воздействие:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        обильное слезотечение и боль в глазах, удушье, приступы кашля, головокружения, рвота, отеки голосовых связок и легких.
                      </v-card-text>

                      <v-card-title class="title red--text">
                        Концентрации:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        <span class="title font-weight-bold">375 мг/м3 </span> - допустимая в течении 10 минут;
                      </v-card-text>
                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        <span class="title font-weight-bold">1400 мг/м3 </span> - летальная концентрация.
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red"
                          text
                          @click="dialog7 = false"
                        >
                          Закрыть
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </span>
                <span class="mr-10">
                  <v-dialog
                    v-model="dialog8"
                    width="50%"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        rounded
                        color="red title"
                        dark
                        x-large
                        v-bind="attrs"
                        v-on="on"
                      >
                        SO<sub>2</sub>
                      </v-btn>
                    </template>

                    <v-card class="noselect">
                      <v-card-title class="headline">
                        Сернистый ангидрид (диоксид серы, сернистый газ), SO<sub>2</sub>
                      </v-card-title>

                      <v-card-title class="title red--text">
                        Воздействие:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        кашель, носовые кровотечения, спазм бронхов, нарушает обменные процессы, способствует образованию метгемоглобина в крови, действует на кроветворные органы.
                      </v-card-text>

                      <v-card-title class="title red--text">
                        Концентрации:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        <span class="title font-weight-bold">250-500 мг/м3 </span> – опасная концентрация;
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        <span class="title font-weight-bold">1500-2000 мг/м3 </span> – смертельная концентрация при воздействии в течение нескольких минут.
                      </v-card-text>
                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red"
                          text
                          @click="dialog8 = false"
                        >
                          Закрыть
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </span>
              </div>
              <div class="d-flex justify-end mb-10">
                <span class="mr-10">
                  <v-dialog
                    v-model="dialog9"
                    width="50%"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        rounded
                        color="red title"
                        dark
                        x-large
                        v-bind="attrs"
                        v-on="on"
                      >
                        H<sub>2</sub>S
                      </v-btn>
                    </template>

                    <v-card class="noselect">
                      <v-card-title class="headline">
                        Сероводород, H<sub>2</sub>S
                      </v-card-title>

                      <v-card-title class="title red--text">
                        Воздействие:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        раздражение глаз и дыхательных путей, судороги, потеря сознания.
                      </v-card-text>

                      <v-card-title class="title red--text">
                        Концентрации:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        <span class="title font-weight-bold">700 мг/м3 </span> - тяжелое отравление;
                      </v-card-text>
                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        <span class="title font-weight-bold">1000 мг/м3 </span> - смерть в течение нескольких минут.
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red"
                          text
                          @click="dialog9 = false"
                        >
                          Закрыть
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </span>
                <span class="mr-10">
                  <v-dialog
                    v-model="dialog10"
                    width="50%"
                    >
                    <template v-slot:activator="{ on, attrs }">

                      <v-btn
                        rounded
                        color="red title"
                        dark
                        x-large
                        v-bind="attrs"
                        v-on="on"
                      >
                        CH<sub>2</sub>
                      </v-btn>
                    </template>

                    <v-card class="noselect">
                      <v-card-title class="headline">
                        Акролеин (акриловый альдегид), CH<sub>2</sub>
                      </v-card-title>

                      <v-card-title class="title red--text">
                        Воздействие:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        легкое головокружение, приливы крови к голове, тошнота, рвота, замедление пульса, потеря сознания, отек легких.
                      </v-card-text>

                      <v-card-title class="title red--text">
                        Концентрации:
                      </v-card-title>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        <span class="title font-weight-bold">13мг/м3 </span> – допустимая в течении 1 минуты;
                      </v-card-text>

                      <v-card-text class="title font-weight-light">
                        <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                        <span class="title font-weight-bold">75-350 мг/м3 </span> – летальная концентрация.
                      </v-card-text>
                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red"
                          text
                          @click="dialog10 = false"
                        >
                          Закрыть
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </span>
              </div>
            </div>

          </div>
          <Hint></Hint>
          <v-spacer></v-spacer>
          <div>
            <v-img
              lazy-src="img/material/smoke-img.svg"
              max-width="100%"
              max-height="100%"
              src="img/material/smoke-img.svg"
              class="jystify-center"
            ></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: null,
    dialog2: null,
    dialog3: null,
    dialog4: null,
    dialog5: null,
    dialog6: null,
    dialog7: null,
    dialog8: null,
    dialog9: null,
    dialog10: null
  }),
  components: {
    Hint
  }
}
</script>
